<template>
  <div>
    <b-modal
      v-model="isModalVisible"
      hide-header
      footer-class="border-top-0"
      header-class="border-bottom-0"
      @ok="close"
      @cancel="close"
    >
      <div class="text-center my-3">
        <div class="spinner"></div>
        <h4 class="mt-4">{{ $t("loadingModal.title") }}</h4>
        <p class="mt-4" style="color: var(--emotiva-white-text)">
          {{ $t("loadingModal.bodyText") }}
        </p>
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="none" class="cancel" @click="cancel">{{
          $t("button.close")
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: null,
      pollInterval: null,
    };
  },
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToProject(){
        this.$router.push({
          name: "project-analysis",
          params: {
            id: this.projectId
          }
        });
    },
    async fetchProject() {
      try {
        await this.$store.dispatch(
          "project/retrieveProjectByClientId",
          this.projectId
        );
        const project = this.$store.getters['project/getActiveProject'];
        if(project.status==='closed'){
            this.isModalVisible = false;
            this.goToProject();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.isModalVisible = false;
      } 
    },
    startPolling() {
      this.fetchProject(); // Initial fetch
      this.pollInterval = setInterval(this.fetchProject, 15000); // 15 seconds
    },
    close() {
      this.$emit("close");
    },
  },
  watch: {
    modalStatus: {
      handler: function (value, oldValue) {
        if (value) {
          this.startPolling();
        } else {
          clearInterval(this.pollInterval);
        }
      },
    },
  },
  computed: {
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  },
};
</script>

<style scoped>
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #6f14ef;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}
.cancel {
  border: 2px solid #cbd2e0;
  border-color: #cbd2e0;
  color: var(--emotiva-white-text);
  font-weight: 700;
  border-radius: 26px;
  height: 46px;
  min-width: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div>
    <b-form-input class="searchbar" :placeholder="placeholder" v-model="searchWord" @input="handleInput"> </b-form-input>
  </div>
</template>

<script>
export default {
  props: {
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return { searchWord: "", placeholder: this.$t("search") };
  },
  methods: {
    handleInput(){
      this.$store.commit("project/SET_SEARCH_WORD",this.searchWord)
    }
  },
  beforeDestroy(){
    this.$store.commit("project/SET_SEARCH_WORD","")
  },
  beforeCreate(){
    this.$store.commit("project/SET_SEARCH_WORD","")
  }
};
</script>

<style scoped>
.searchbar {
  border-radius: 40px;
  background-color: #edf0f7;
  height: 48px;
}
</style>

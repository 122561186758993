<template>
  <div class="">
    <div v-if="hasProjects">
      <div
        v-for="(projectInfo, index) in projectList.slice(1)"
        :key="index"
        class="pr-5 pl-5 pt-3"
      >
        <div v-if="!isListEmpty(projectInfo.list)">
          <div class="title__1">
            {{ $t(`page.dashboard.project.status.${projectInfo.label}.title`) }}
          </div>
          <p class="text-muted subtitle__1">
            {{
              $t(
                `page.dashboard.project.status.${projectInfo.label}.description`
              )
            }}
          </p>
          <div
            v-if="isListEmpty(projectInfo.list)"
            class="font-italic text-uppercase"
            v-html="
              $t(`page.dashboard.project.status.${projectInfo.label}.emptyList`)
            "
          ></div>
          <div v-else>
            <b-row class="" v-if="index === 1">
              <b-col
                xl="2"
                lg="2"
                md="3"
                sm="4"
                v-for="project in filteredCompletedProjectList"
                :key="project.projectId"
                class="mt-1 mb-3 project__list__item"
              >
                <project-dashboard-list-item
                  :project="project"
                  :projectStatus="projectInfo.status"
                />
              </b-col>
            </b-row>
            <b-row class="" v-else>
              <b-col
                xl="2"
                lg="2"
                md="3"
                sm="4"
                v-for="project in projectInfo.list"
                :key="project.projectId"
                class="mt-1 mb-3 project__list__item"
              >
                <project-dashboard-list-item
                  :project="project"
                  :projectStatus="projectInfo.status"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mt-5 text-center" style="color:var(--emotiva-white-text)">
      <h3 class="mb-3">{{ $t("page.projects.noPublishedProjects.title") }}</h3>
      <div v-if="isUserAllowedProjectHandling">
        <h6 class="mb-0" style="font-weight: 300">
          {{ $t("page.projects.noPublishedProjects.subtitle-1") }}
        </h6>
        <h6 class="mb-0" style="font-weight: 300">
          {{ $t("page.projects.noPublishedProjects.subtitle-2") }}
        </h6>
        <b-button
          class="justify-content-center align-items-center bold emotiva-pink-background action-btn mt-5"
          size="sm"
          variant="none"
          style="color: #fff;"
          @click="hanldeCreateProjectAction"
        >
        <img
              src="../../assets/general-icons/startAnalysis-icon.svg"
              alt=""
            />
            <span class="ml-2">{{ $t("button.analysis") }}</span>
          
        </b-button>
      </div>
    </div>
    <new-project-modal
      :modalStatus="modalStatus"
      :projectName="projectName"
      :projectType="projectType"
      :branchList="isMultiuserAdmin ? branchList : []"
      :projectBranchId="projectBranchId"
      @setModalStatus="setModalStatus"
      @setProjectName="setProjectName"
      @setProjectBranchId="setProjectBranchId"
      @submit="fetchProjects"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import Utils from "./../../Utils";
import ProjectDashboardListItem from "./ProjectDashboardListItem";
import NewProjectModal from "./../NewProject/NewProjectModal";
export default {
  components: {
    ProjectDashboardListItem,
    NewProjectModal,
  },
  data() {
    return {
      modalStatus: false,
      projectName: "",
      projectBranchId: "",
      projectType: "custom",
    };
  },
  computed: {
    userType() {
      return this.$store.getters["auth/getUserType"];
    },
    userSubType() {
      return this.$store.getters["auth/getUserSubType"];
    },
    isMultiUser() {
      return this.$store.getters["auth/isMultiUser"];
    },
    serachWord() {
      return this.$store.getters["project/getSearchWord"];
    },
    filteredCompletedProjectList() {
      let projectList = this.projectList[2].list;
      projectList = projectList.filter((project) =>
        project.name.toLowerCase().includes(this.serachWord.toLowerCase())
      );
      return projectList;
    },

    projectList() {
      let projectList;
      if (this.$route.name === "Folder") {
        projectList = this.$store.getters["folder/getProjectList"];
      } else {
        projectList = this.$store.getters["project/getProjectList"];
      }
      // const projectList = this.$store.getters["project/getProjectList"];
      const sortedProjects = projectList.map((projectGroup) => {
        const list = [...projectGroup.list].sort((projectA, projectB) => {
          if (dayjs(projectA.createdAt).isBefore(projectB.createdAt)) return 1;
          else return -1;
        });

        return {
          ...projectGroup,
          list,
        };
      });
      if (sortedProjects) {
      }
      return sortedProjects;
    },
    isMultiuserAdmin() {
      if (
        this.userType === Utils.USER_TYPE.CUSTOMER &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.ADMIN
      ) {
        return true;
      } else {
        return false;
      }
    },
    branchList() {
      return this.$store.getters["multiuser/getBranches"];
    },
    hasProjects() {
      if (this.$route.name === "Folder") {
        return true;
      }
      let hasEntries = false;
      for (const key in this.projectList) {
        if (this.projectList[key].list.length > 0) {
          hasEntries = true;
          break;
        }
      }
      return hasEntries;
    },
    isUserAllowedProjectHandling() {
      // custom + multiuser + researchers are not allowed to create, edit, delete a project
      if (
        this.userType === Utils.USER_TYPE.CUSTOMER &&
        this.isMultiUser &&
        this.userSubType === Utils.USER_SUBTYPE.RESEARCHER
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    fetchProjects() {
      // refetch after creating one
      this.$store.dispatch("project/retrieveProjectsByClientId");
    },
    isListEmpty(list) {
      return list.length === 0;
    },
    isActiveProjectList(status) {
      return status === Utils.PROJECT_STATUS.ACTIVE;
    },
    hanldeCreateProjectAction() {
      this.openModal();
    },
    openModal() {
      this.modalStatus = true;
    },
    setModalStatus(modalStatus) {
      this.modalStatus = modalStatus;
    },
    setProjectName(projectName) {
      this.projectName = projectName;
    },
    setProjectBranchId(projectBranchId) {
      this.projectBranchId = projectBranchId;
    },
    includesSearchWord(project) {
      if (this.serachWord === "") return true;
      return project.name.includes(this.serachWord);
    },
  },
};
</script>

<style scoped>
.project__list__item {
  width: 190px;
  /* min-height: 131px; */
}

.create-project-btn {
  font-size: 0.8rem;
  font-weight: 600;
}
.bold {
  font-weight: bold;
}
.action-btn {
  border-radius: 26px;
  height: 46px;
  min-width: 100px;
}

@media screen and (max-width: 400px) {
  .project__list__item {
    min-width: unset;
  }
}
</style>

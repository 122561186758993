<template>
  <b-modal v-model="isModalVisible" size="md" dialog-class="mt-md-5 p-0" content-class="p-2"
  footer-class="border-top-0" header-class="border-bottom-0" @ok="submit" @cancel="cancel">
    <template v-slot:modal-header>
      <h5 class="mb-0 text-uppercase font-weight-bold">
        {{ $t("addFolder") }}
      </h5>
    </template>
    
    <template v-slot:default>
      <p class="font-weight-bold label">
        {{ $t("name") }}
      </p>
      <b-form-input v-model="newFolderName"> </b-form-input>
    </template>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button
        variant="none"
        style="color:var(--emotiva-white-text)"
        class="mr-3 action-btn white-border"
        @click="cancel"
        >{{ $t("button.cancel") }}</b-button
      >
      <b-button
      variant="none"
      style="color: #fff;"
      class="action-btn emotiva-pink-background"
        :disabled="!isFormValid"
        @click="ok"
        >{{ $t("button.ok") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
    folderName: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      localFolderName: this.folderName
    };
  },
  watch: {
    folderName(newVal) {
      this.localFolderName = newVal;
    },
    localFolderName(newVal) {
      this.$emit("setFolderName", newVal);
    }
  },
  computed: {
    isFormValid() {
      return this.localFolderName.length > 0;
    },
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },
    newFolderName: {
      get() {
        return this.localFolderName;
      },
      set(folderName) {
        this.localFolderName = folderName;
      },
    }
  },
  methods: {
    submit() {
      // create folder
      this.$emit("submit");
    },
    cancel() {
      this.localFolderName = "";
      this.$emit("setFolderName", "");
    }
  }
}
</script>

<style scoped>
.action-btn {
  height: 46px;
  border-radius: 20px; 
  min-width: 80px;
}
.white-border{
  border: 2px solid #cbd2e0;
  border-color: #cbd2e0;
}
</style>
